import { Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable, of, startWith } from 'rxjs';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import { ApplicationStore, InsuranceObjectType, Person, Role } from '@nbg-digital/application/domain';
import { PricingFacade } from '@nbg-digital/pricing/api';
import { UserManagementFacade } from '@nbg-digital/user-management/api';
import { WizardPageComponent } from '@nbg-digital/ui-dpl-components';
import {
  PersonalDetails,
  PersonalDetailsComponent,
} from '../../components/personal-details/personal-details.component';
import { AddressData, AddressDataComponent } from '../../components/address-data/address-data.component';
import { AppConfigService } from '@nbg-digital/shared/util';

import { PersonalDetailsConfig } from '../../components/personal-details/personal-details-config';
import {
  COMPLETE_CUSTOMER_DATA_CONFIG,
  CUSTOMER_DATA_WITHOUT_CITY_OF_BIRTH_CONFIG,
  DEFAULT_ADDRESS_DATA_CONFIG,
  DEFAULT_PERSONAL_DETAILS_CONFIG,
} from './personal-data-config';

@Component({
  selector: 'nv-application-personal-data',
  templateUrl: './personal-data.component.html',
})
export class PersonalDataComponent extends WizardPageComponent implements OnDestroy {
  @ViewChild(PersonalDetailsComponent) personalDetailsComponent: PersonalDetailsComponent;
  @ViewChild(AddressDataComponent) addressDataComponent: AddressDataComponent;
  initialPersonalDetails: Partial<PersonalDetails>;
  initialAddress: AddressData;
  personalDetailsConfig$: Observable<PersonalDetailsConfig>;
  addressDataConfig = DEFAULT_ADDRESS_DATA_CONFIG;

  personalDataForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    public store: ApplicationStore,
    private pricingFacade: PricingFacade,
    route: ActivatedRoute,
    private userManagementFacade: UserManagementFacade,
    element: ElementRef,
    public appConfigService: AppConfigService,
    @Inject('enableUserLogin') private enableUserLogin: boolean
  ) {
    super(route, element);
    this.personalDataForm = this.fb.group({
      personalDetails: null,
      address: null,
    });

    const isAuthenticated$ = this.enableUserLogin ? this.userManagementFacade.isAuthenticated$ : of(false);
    this.personalDetailsConfig$ = combineLatest([
      isAuthenticated$,
      this.store.select((state) => state.insuranceObjects.POLICY_HOLDER.cityOfBirth),
    ]).pipe(
      map(([isAuthenticated, cityOfBirth]) => {
        if (isAuthenticated && !!cityOfBirth) {
          return COMPLETE_CUSTOMER_DATA_CONFIG;
        } else if (isAuthenticated) {
          return CUSTOMER_DATA_WITHOUT_CITY_OF_BIRTH_CONFIG;
        } else {
          return DEFAULT_PERSONAL_DETAILS_CONFIG;
        }
      }),
      startWith(DEFAULT_PERSONAL_DETAILS_CONFIG)
    );
  }

  protected isFormValid() {
    this.personalDataForm.markAllAsTouched();
    return this.personalDataForm.valid;
  }

  protected beforeNext() {
    this.setInsuranceObject();
  }

  private setInsuranceObject() {
    const insuranceObject = {
      type: InsuranceObjectType.Person,
      roles: [Role.PolicyHolder, Role.Insuree, Role.PremiumPayer],
      ...this.personalDetailsComponent.value,
      address: this.addressDataComponent.value,
    };
    this.store.setInsuranceObject(insuranceObject);
  }

  protected fillInitialDataFromStore() {
    this.pricingFacade.pricing$
      .pipe(first())
      .subscribe(
        (pricing) => (this.initialPersonalDetails = { birthDate: pricing.birthDate, salutation: pricing.salutation })
      );

    this.store
      .select((state) => state.insuranceObjects?.INSUREE)
      .pipe(
        filter((insuree) => !!insuree),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((insuree) => this.fillInsuranceObject(insuree));
  }

  private fillInsuranceObject(insuree: Person) {
    const { firstname, lastname, cityOfBirth, phone, nationality, address } = insuree;
    this.initialPersonalDetails = {
      ...this.initialPersonalDetails,
      firstname,
      lastname,
      phone,
      cityOfBirth,
      nationality,
    };
    this.initialAddress = address;
  }

  formInitialized(name: string, form: UntypedFormGroup) {
    this.personalDataForm.setControl(name, form);
  }
}
